import React, { useEffect, useState } from "react";
import TransitionedBlock from "../../../HelperComponents/TransitionedBlock/TransitionedBlock";
import { withTranslation, Link, i18n } from "../../../../i18n";
import { API_BASE_URL } from "../../../../context/appState";
import { useRouter } from "next/router";
import { getOption, sendRequest } from "../../../HelperComponents/functions";
import MapContainer from "../../../CommonBlocks/GoogleMapContainer/GoogleMapContainer";
import mail_contact from "../../../../public/images/mail_contact.png";
import phone_contact from "../../../../public/images/phone_contact.png";
import Head from "next/head";

import "./KontaktyBlock.scss";

const KontaktyBlock = ({ t, shopList }) => {
    const { language } = i18n;
    const router = useRouter();

    useEffect(() => {
        setChosenProrabShop(prorabShops[router.query && router.query.map ? router.query.map : 0]);
    }, [router.query]);

    const makeTime = shop => {
        let FT = formatTime;
        if (shop) {
            return ` 
                <div>${daysList.map(el => `<span>${t(el.ruName)}:</span>`).join('')}</div>
                <div>
                ${daysList.map(el => !!shop[`${el.shortName}_from`] && !!shop[`${el.shortName}_to`]
                ? `<span>${FT(shop[`${el.shortName}_from`])} - ${FT(shop[`${el.shortName}_to`])}</span>`
                : `<span>${t('Выходной')}</span>`).join('')}
                </div>
    `;
        } else {
            return "";
        }
    };

    const getPBDepartment = data => {
        let tempArray = [];
        data.forEach(el => {
            tempArray.push({
                label: getOption(`${el.address} `),
                label_ua: getOption(`${el.address_ua} `),
                value: `${el.address} `,
                value_ua: `${el.address_ua} `,
                data: el
            });
        });
        return tempArray
    };

    const prorabShops = getPBDepartment(shopList);
    const [chosenProrabShop, setChosenProrabShop] = useState(prorabShops[0]);

    return (
        <TransitionedBlock>
            <Head>
                <title>{t("Контакты")} │ PRORAB</title>
            </Head>
            <div className="kontakty_wrapper">
                <h1 className="title">{t("Контакты")}</h1>
                <div className="kontakty_address">
                    {prorabShops.map((el, idx) => {
                        return (
                            <>
                                <Link href={`/kontakty?map=${idx}`}>
                                    <a>
                                        <div className={el === chosenProrabShop ? "active" : ""}>
                                            {language === "ua" ? el.value_ua : el.value}
                                        </div>
                                    </a>
                                </Link>
                                {idx !== prorabShops.length - 1 && <span> / </span>}
                            </>
                        )
                    })}
                </div>

                <div className="map_block">
                    <div style={{ height: "380px", width: "100%" }}>
                        <MapContainer
                            special={true}
                            lng={chosenProrabShop && chosenProrabShop.data ? chosenProrabShop.data.longitude : 0}
                            lat={chosenProrabShop && chosenProrabShop.data ? chosenProrabShop.data.latitude : 0}
                        />
                    </div>
                    {chosenProrabShop && (
                        <div className="time_work" dangerouslySetInnerHTML={{ __html: makeTime(chosenProrabShop.data) }} />
                    )}
                </div>

                <div className="kontakty_phone">
                    <div className="contact-phone-wrapper">
                        <img src={phone_contact} alt="phone_contact" />{" "}
                        <a className={`binct - phone - number - 1`} style={{ color: "inherit" }} href="tel:0800330757">
                            0 800 33 07 57
                        </a>
                        <a className={`binct - phone - number - 1`} style={{ color: "inherit" }} href="tel:0997730444">
                            099 773 04 44
                        </a>
                        <a className={`binct - phone - number - 1`} style={{ color: "inherit" }} href="tel:0977730444">
                            097 773 04 44
                        </a>
                    </div>
                    <div className="">
                        <img src={mail_contact} alt="mail_contact" />{" "}
                        <a style={{ color: "inherit" }} href="mailto:poltava.prorab@gmail.com">
                            poltava.prorab@gmail.com
                        </a>
                    </div>
                </div>
            </div>
        </TransitionedBlock>
    );
};

export const formatTime = time => {
    return [time.split(":")[0], time.split(":")[1]].join(":");
};

export const daysList = [
    {
        shortName: 'mon',
        ruName: 'Понедельник'
    },
    {
        shortName: 'tue',
        ruName: 'Вторник'
    },
    {
        shortName: 'wed',
        ruName: 'Среда'
    },
    {
        shortName: 'thu',
        ruName: 'Четверг'
    },
    {
        shortName: 'fri',
        ruName: 'Пятница'
    },
    {
        shortName: 'sat',
        ruName: 'Суббота'
    },
    {
        shortName: 'sun',
        ruName: 'Воскресенье'
    },
]

export default withTranslation("services")(KontaktyBlock);
