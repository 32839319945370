import React from "react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";

const mapStyles = {
    width: "inherit",
    height: "140px"
};
const specialMapStyles = {
    width: "600px",
    height: "450px"
};
const containerStyle = {
    position: "relative"
};

export const MapContainer = props => {
    return (
        <Map
            containerStyle={containerStyle}
            google={props.google}
            zoom={15}
            style={!!props.special ? specialMapStyles : mapStyles}
            initialCenter={{ lat: +props.lat, lng: +props.lng }}
            center={{ lat: +props.lat, lng: +props.lng }}
        >
            <Marker position={{ lat: +props.lat, lng: +props.lng }} />
        </Map>
    );
};

export default GoogleApiWrapper({
    apiKey: "AIzaSyCog0QrJh4EkygZBvzxy80UeoVZJdjrxdM"
})(MapContainer);
